import React from 'react';
import Modal from 'react-modal';
import X from '../svg/x.svg';

class Extra extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    const customStyles = {
      overlay: { backgroundColor: 'white' },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0px',
        border: '1px black solid',
      },
    };

    return (
      <div>
        <button
          className="font-bold hover:underline"
          onClick={this.handleOpenModal}
        >
          {this.props.title}
        </button>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          style={customStyles}
        >
          <button
            className="flex-col items-end p-8 mb-2"
            onClick={this.handleCloseModal}
          >
            <X className="opacity-100 hover:opacity-50" />
          </button>
          <p className="px-8 md:px-24">{this.props.content}</p>
        </Modal>
      </div>
    );
  }
}

export default Extra;
