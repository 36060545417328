import React, { useRef } from 'react';
import Seo from '../components/SEO';
import Modal from '../components/Modal';

import useSound from 'use-sound';
import Ring from '../sounds/ring.mp3';
import Rail from '../sounds/rail.mp3';
import Bell from '../sounds/bell.mp3';

import Tee from '../svg/garments/tee.svg';
import Jumper from '../svg/garments/jumper.svg';
import Hoodie from '../svg/garments/hoodie.svg';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function StuffPage() {
  const myRefContact = useRef(null);
  const myRefDelivery = useRef(null);
  const myRefSizing = useRef(null);

  const [ring] = useSound(Ring, { volume: 0.25 });
  const [rail] = useSound(Rail, { volume: 0.1 });
  const [bell] = useSound(Bell, { volume: 0.25 });

  return (
    <section
      className="mt-16 text-primary md:mt-20 lg:mt-12"
    >
      <Seo pageTitle="stuff" />
      <div className="flex flex-col justify-center flex-grow -mb-px border lg:flex-row border-primary ">
        <div
          style={{ height: '80vh' }}
          className="flex flex-col justify-center p-8 lg:w-2/4 md:w-full items-left md:p-16"
        >
          <p className="font-serif text-5xl font-extrabold leading-tight md:text-6xl">
            boring stuff
          </p>
          <br />
          <p className="text-base">
            here you’ll find all else that you need to know.
          </p>
        </div>
        <div className="-ml-px -mr-px lg:w-2/4 md:w-full md:-ml-px">
          <div className="flex flex-col justify-between w-full h-full -mr-px">
            <button
              className="flex flex-col items-center justify-center w-full h-32 -mb-px text-2xl font-bold border border-r-0 lg:h-full border-primary lg:border-t-0 border-t-1 hover:bg-primary hover:text-white"
              onClick={() => {
                scrollToRef(myRefContact);
                ring();
              }}
            >
              contact
            </button>

            <button
              className="flex flex-col items-center justify-center w-full h-32 -mb-px text-2xl font-bold border border-r-0 lg:h-full border-primary hover:bg-primary hover:text-white"
              onClick={() => {
                scrollToRef(myRefDelivery);
                bell();
              }}
            >
              delivery
            </button>

            <button
              className="flex flex-col items-center justify-center w-full h-32 -mb-px text-2xl font-bold border border-r-0 lg:h-full border-primary hover:bg-primary hover:text-white"
              onClick={() => {
                scrollToRef(myRefSizing);
                rail();
              }}
            >
              sizing
            </button>
          </div>
        </div>
      </div>
      <section
        ref={myRefContact}
        className="flex flex-col items-center justify-center w-full p-8 border border-primary md:p-16 lg:p-24"
        id="contact"
      >
        <p className="mt-4 font-serif text-5xl font-extrabold">contact</p>
        <div className="max-w-2xl p-8 text-base leading-loose text-center">
          <br />
          please contact us on the email below <br /> and we will get back to
          you as soon as we can.
          <br />
          <br />
          <br />
          <b className="text-xl">ALL CUSTOMER QUESTIONS</b>
          <br /> sizing, fit, style, anything you want to know.
          <br /> <br />
          <a className="hover:underline" href="mailto:hello@deadnice.co">
            <b>hello@deadnice.co</b>
          </a>
          <br />
          <br />
          <br /> <b className="text-xl">RETURNS + EXCHANGES</b>
          <br /> want to return something? Easy! <br />
          <br />
          we will exchange or refund any items returned within 14 days of the
          package arrival date if it is in same as new condition. Not all items
          are subject to this exchange policy as availability is subject to
          change but, returns can still be made for a full refund.
          <br />
          <br /> please note that we cannot refund the original shipping charges
          (standard first class/airmail) unless items are faulty or shipped
          incorrectly.
          <br />
          <a className="hover:underline" href="mailto:hello@deadnice.co">
            <br />
            <b>hello@deadnice.co</b>
          </a>
          <br />
          <br />
          <br />
          <b className="text-xl">MEDIA + PRESS ENQUIRIES</b>
          <br /> we are accepting requests for collaborations.
          <br /> <br />
          <a className="hover:underline" href="mailto:hello@deadnice.co">
            <b>hello@deadnice.co</b>
          </a>
        </div>
      </section>
      <section
        ref={myRefDelivery}
        className="flex flex-col items-center justify-center w-full p-8 -mt-px border border-primary md:p-16 lg:p-24"
        id="delivery"
      >
        <p className="mt-4 font-serif text-5xl font-extrabold">delivery</p>
        <p className="text-base leading-loose text-center">
          <div className="pt-20">
            <b className="text-xl">FREE SHIPPING</b>
            <br />
            we offer free standard delivery to mainland UK
            <br />
          </div>
          <div className="pt-20">
            <b className="text-xl">DELIVERY TIMES</b>
            <br /> 5-7 days at max but usually 2.
          </div>
        </p>
        <div className="max-w-5xl leading-loose text-center">
          <div className="pt-20">
            <b className="text-xl">SOMETHING WRONG?</b>
            <br /> not to worries, give us a message and we’ll sort it
            <br /> <br />
            <a className="hover:underline" href="mailto:hello@deadnice.co">
              <b>hello@deadnice.co</b>
            </a>
            <br />
          </div>
        </div>
      </section>
      <section
        ref={myRefSizing}
        className="flex flex-col items-center justify-center w-full p-8 -mt-px border border-primary md:p-16 lg:p-24"
        id="sizing"
      >
        <p className="mt-4 font-serif text-5xl font-extrabold">sizing</p>
        <p className="max-w-2xl py-8 text-base text-center leading-loosem">
          all our clothes are unisex, a general rule to keep is; if you are a
          females MEDIUM, get a SMALL - a size down. Males get the size you
          would normally. <br />
        </p>
        <div className="flex my-16">
          <Modal title="tee" content={<Tee width="100%" />} />
          <p className="px-4">•</p>
          <Modal title="hoodie" content={<Hoodie width="100%" />} />
          <p className="px-4">•</p>
          <Modal title="jumper" content={<Jumper width="100%" />} />
        </div>
        <p>if you need any help, please do reach out - we'll sort you out.</p>
      </section>
      <section className="pb-20 -mt-px border border-primary">
        <p className="mt-24 font-serif text-5xl font-extrabold text-center">
          extras
        </p>
        <div className="flex justify-center p-8 align-center">
          <Modal
            title="terms of use"
            content="
By using this website, you are agreeing to the terms set on this page. Terms are subject to change so this page should be checked frequently. We reserve the right to change any pricing and availability as information on this website may not be completely accurate or current."
          />
          <p className="px-4">•</p>
          <Modal
            title="privacy policy"
            content={
              <div>
                <b>
                  PRIVACY NOTICE
                  <b />
                </b>{' '}
                <br />
                <br />
                Last updated November 07, 2020 <br />
                Thank you for choosing to be part of our community at
                deadnice.co (" Company ", "we", "us", "our"). We are committed
                to protecting your personal information and your right to
                privacy. If you have any questions or concerns about this
                privacy notice, or our practices with regards to your personal
                information, please contact us at hello@deadnice.co.
                <br />
                <br />
                When you visit our website{' '}
                <a href="http://www.deadnice.co">
                  <b>http://www.deadnice.co</b>
                </a>{' '}
                (the "Website"), and more generally, use any of our services
                (the "Services", which include the Website ), we appreciate that
                you are trusting us with your personal information. We take your
                privacy very seriously. In this privacy notice, we seek to
                explain to you in the clearest way possible what information we
                collect, how we use it and what rights you have in relation to
                it. We hope you take some time to read through it carefully, as
                it is important. If there are any terms in this privacy notice
                that you do not agree with, please discontinue use of our
                Services immediately.
                <br />
                <br /> This privacy notice applies to all information collected
                through our Services (which, as described above, includes our
                Website ), as well as, any related services, sales, marketing or
                events. <br />
                <br />
                <b>
                  Please read this privacy notice carefully as it will help you
                  understand what we do with the information that we collect.
                </b>
                <br />
                <br />
                <b>
                  TABLE OF CONTENTS <br />
                  1. WHAT INFORMATION DO WE COLLECT? <br />
                  2. HOW DO WE USE YOUR INFORMATION? <br />
                  3. WILL YOUR INFORMATION BE SHARED WITH ANYONE? <br />
                  4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? <br />
                  5. HOW LONG DO WE KEEP YOUR INFORMATION? <br />
                  6. HOW DO WE KEEP YOUR INFORMATION SAFE? <br />
                  7. DO WE COLLECT INFORMATION FROM MINORS? <br />
                  8. WHAT ARE YOUR PRIVACY RIGHTS? <br />
                  9. CONTROLS FOR DO-NOT-TRACK FEATURES <br />
                  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{' '}
                  <br />
                  11. DO WE MAKE UPDATES TO THIS NOTICE? <br />
                  12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? <br />
                  13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                  FROM YOU?{' '}
                </b>{' '}
                <br /> <br />
                <b>1. WHAT INFORMATION DO WE COLLECT? </b> <br />
                <br />
                <b>In short:</b> We collect personal information that you
                provide to us.
                <br />
                <br /> We collect personal information that you voluntarily
                provide to us when you express an interest in obtaining
                information about us or our products and Services, when you
                participate in activities on the Website or otherwise when you
                contact us. <br />
                The personal information that we collect depends on the context
                of your interactions with us and the Website , the choices you
                make and the products and features you use. The personal
                information we collect may include the following: Personal
                Information Provided by You. We collect email addresses; names;
                and other similar information. Payment Data. We may collect data
                necessary to process your payment if you make purchases, such as
                your payment instrument number (such as a credit card number),
                and the security code associated with your payment instrument.
                All payment data is stored by Stripe and Paypal . <br />
                You may find their privacy notice link(s) here:
                https://stripe.com/gb/privacy and
                https://www.paypal.com/us/webapps/mpp/ua/privacy-full . All
                personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
                <br /> Information automatically collected
                <br />
                <b>In short:</b> Some information — such as your Internet
                Protocol (IP) address and/or browser and device characteristics
                — is collected automatically when you visit our Website . We
                automatically collect certain information when you visit, use or
                navigate the Website . This information does not reveal your
                specific identity (like your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Website and
                other technical information. <br />
                This information is primarily needed to maintain the security
                and operation of our Website , and for our internal analytics
                and reporting purposes. Like many businesses, we also collect
                information through cookies and similar technologies. The
                information we collect includes: Log and Usage Data. Log and
                usage data is service-related, diagnostic, usage and performance
                information our servers automatically collect when you access or
                use our Website and which we record in log files. Depending on
                how you interact with us, this log data may include your IP
                address, device information, browser type and settings and
                information about your activity in the Website (such as the
                date/time stamps associated with your usage, pages and files
                viewed, searches and other actions you take such as which
                features you use), device event information (such as system
                activity, error reports (sometimes called 'crash dumps') and
                hardware settings). Device Data. We collect device data such as
                information about your computer, phone, tablet or other device
                you use to access the Website . Depending on the device used,
                this device data may include information such as your IP address
                (or proxy server), device and application identification
                numbers, location, browser type, hardware model Internet service
                provider and/or mobile carrier, operating system and system
                configuration information. Location Data. We collect location
                data such as information about your device's location, which can
                be either precise or imprecise. How much information we collect
                depends on the type and settings of the device you use to access
                the Website . For example, we may use GPS and other technologies
                to collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. Note however, if you choose to opt out, you may not be
                able to use certain aspects of the Services. <br />
                <br />
                <b> 2. HOW DO WE USE YOUR INFORMATION?</b>
                <br />
                <br />
                <b>In short:</b> We process your information for purposes based
                on legitimate business interests, the fulfillment of our
                contract with you, compliance with our legal obligations, and/or
                your consent. <br /> <br />
                We use personal information collected via our Website for a
                variety of business purposes described below. We process your
                personal information for these purposes in reliance on our
                legitimate business interests, in order to enter into or perform
                a contract with you, with your consent, and/or for compliance
                with our legal obligations. We indicate the specific processing
                grounds we rely on next to each purpose listed below. We use the
                information we collect or receive: To send you marketing and
                promotional communications. We and/or our third-party marketing
                partners may use the personal information you send to us for our
                marketing purposes, if this is in accordance with your marketing
                preferences. For example, when expressing an interest in
                obtaining information about us or our Website , subscribing to
                marketing or otherwise contacting us, we will collect personal
                information from you. You can opt-out of our marketing emails at
                any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below). Deliver
                targeted advertising to you. We may use your information to
                develop and display personalized content and advertising (and
                work with third parties who do so) tailored to your interests
                and/or location and to measure its effectiveness. For other
                business purposes. We may use your information for other
                business purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Website , products,
                marketing and your experience. We may use and store this
                information in aggregated and anonymized form so that it is not
                associated with individual end users and does not include
                personal information. We will not use identifiable personal
                information without your consent. <br />
                <br />
                <b>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE? </b>
                <br />
                <br />
                <b>In short:</b> We only share information with your consent, to
                comply with laws, to provide you with services, to protect your
                rights, or to fulfill business obligations. <br /> <br />
                We may process or share your data that we hold based on the
                following legal basis: Consent: We may process your data if you
                have given us specific consent to use your personal information
                for a specific purpose. Legitimate Interests: We may process
                your data when it is reasonably necessary to achieve our
                legitimate business interests. Performance of a Contract: Where
                we have entered into a contract with you, we may process your
                personal information to fulfill the terms of our contract. Legal
                Obligations: We may disclose your information where we are
                legally required to do so in order to comply with applicable
                law, governmental requests, a judicial proceeding, court order,
                or legal process, such as in response to a court order or a
                subpoena (including in response to public authorities to meet
                national security or law enforcement requirements). Vital
                Interests: We may disclose your information where we believe it
                is necessary to investigate, prevent, or take action regarding
                potential violations of our policies, suspected fraud,
                situations involving potential threats to the safety of any
                person and illegal activities, or as evidence in litigation in
                which we are involved. More specifically, we may need to process
                your data or share your personal information in the following
                situations: Business Transfers. We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company. <br />
                <br />
                <b> 4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</b>
                <br />
                <br /> <b>In short:</b> We may use cookies and other tracking
                technologies to collect and store your information. <br />{' '}
                <br />
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice . <br />
                <br />
                <b>5. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
                <br />
                <br /> <b>In short:</b> We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law. <br /> <br />
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than 2 years . When we have no ongoing
                legitimate business need to process your personal information,
                we will either delete or anonymize such information, or, if this
                is not possible (for example, because your personal information
                has been stored in backup archives), then we will securely store
                your personal information and isolate it from any further
                processing until deletion is possible. <br />
                <br />
                <b>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
                <br />
                <br /> <b>In short:</b> We aim to protect your personal
                information through a system of organizational and technical
                security measures.
                <br /> <br /> We have implemented appropriate technical and
                organizational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorized third parties will not be able to defeat our
                security, and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Website is at your own risk. You should only access
                the Website within a secure environment. <br />
                <br />
                <b>7. DO WE COLLECT INFORMATION FROM MINORS ?</b>
                <br />
                <br />
                <b>In short:</b> We do not knowingly collect data from or market
                to children under 18 years of age.
                <br /> <br /> We do not knowingly solicit data from or market to
                children under 18 years of age. By using the Website , you
                represent that you are at least 18 or that you are the parent or
                guardian of such a minor and consent to such minor dependent’s
                use of the Website . If we learn that personal information from
                users less than 18 years of age has been collected, we will
                deactivate the account and take reasonable measures to promptly
                delete such data from our records. If you become aware of any
                data we may have collected from children under age 18, please
                contact us at hello@deadnice.co . <br />
                <br />
                <b> 8. WHAT ARE YOUR PRIVACY RIGHTS? </b>
                <br />
                <br />
                <b>In short:</b> In some regions, such as the European Economic
                Area, you have rights that allow you greater access to and
                control over your personal information. <br /> <br />
                You may review, change, or terminate your account at any time.
                In some regions (like the European Economic Area), you have
                certain rights under applicable data protection laws. These may
                include the right (i) to request access and obtain a copy of
                your personal information, (ii) to request rectification or
                erasure; (iii) to restrict the processing of your personal
                information; and (iv) if applicable, to data portability. In
                certain circumstances, you may also have the right to object to
                the processing of your personal information. To make such a
                request, please use the contact details provided below. We will
                consider and act upon any request in accordance with applicable
                data protection laws. If we are relying on your consent to
                process your personal information, you have the right to
                withdraw your consent at any time. Please note however that this
                will not affect the lawfulness of the processing before its
                withdrawal, nor will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent. If you are a resident in the European
                Economic Area and you believe we are unlawfully processing your
                personal information, you also have the right to complain to
                your local data protection supervisory authority. You can find
                their contact details here:
                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                If you are a resident in Switzerland, the contact details for
                the data protection authorities are available here:
                https://www.edoeb.admin.ch/edoeb/en/home.html. Cookies and
                similar technologies: Most Web browsers are set to accept
                cookies by default. If you prefer, you can usually choose to set
                your browser to remove cookies and to reject cookies. If you
                choose to remove cookies or reject cookies, this could affect
                certain features or services of our Website . To opt-out of
                interest-based advertising by advertisers on our Website visit
                http://www.aboutads.info/choices/. <br />
                <br />
                <b>9. CONTROLS FOR DO-NOT-TRACK FEATURES </b>
                <br />
                <br />
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
                <br />
                <br />
                <b>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b>
                <br />
                <br /> <b>In short:</b> Yes, if you are a resident of
                California, you are granted specific rights regarding access to
                your personal information. <br /> <br />
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below. If you are under 18 years of
                age, reside in California, and have a registered account with
                the Website , you have the right to request removal of unwanted
                data that you publicly post on the Website . To request removal
                of such data, please contact us using the contact information
                provided below, and include the email address associated with
                your account and a statement that you reside in California. We
                will make sure the data is not publicly displayed on the Website
                , but please be aware that the data may not be completely or
                comprehensively removed from all our systems (e.g. backups,
                etc.). <br />
                <br />
                <b>11. DO WE MAKE UPDATES TO THIS NOTICE? </b>
                <br />
                <br />
                <b>In short:</b> Yes, we will update this notice as necessary to
                stay compliant with relevant laws. We may update this privacy
                notice from time to time. <br /> <br />
                The updated version will be indicated by an updated "Revised"
                date and the updated version will be effective as soon as it is
                accessible. If we make material changes to this privacy notice,
                we may notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information. <br />
                <br />
                <b> 12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b>
                <br />
                <br /> If you have questions or comments about this notice, you
                may email us at hello@deadnice.co or by post to:
                <br />
                <br /> deadnice.co
                <br /> 34 Dagnan Road
                <br /> London
                <br /> SW12 9LQ
                <br /> United Kingdom <br />
                <br />
                <b>
                  13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?{' '}
                </b>
                <br />
                <br />
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it in some
                circumstances. To request to review, update, or delete your
                personal information, please visit:{' '}
                <a href="http://www.deadnice.co/stuff">
                  <b>http://www.deadnice.co/stuff</b>
                </a>
                . We will respond to your request within 30 days. This privacy
                policy was created using Termly’s Privacy Policy Generator.
              </div>
            }
          />
        </div>
      </section>
    </section>
  );
}

export default StuffPage;
